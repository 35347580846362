/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
:root {
  --font-sans: 'Inter', sans-serif;
  --font-title: 'Be Vietnam Pro', sans-serif;

  --color-primary-100: #c8d8ff;
  --color-primary-200: #90afff;
  --color-primary-300: #5886ff;
  --color-primary-400: #205eff;
  --color-primary-500: #0040e7;
  --color-primary-600: #0033b9;
  --color-primary-700: #00278b;
  --color-primary-800: #001a5d;
  --color-primary-900: #000d2f;

  --color-secondary-50: #fffcef;
  --color-secondary-100: #fff7d3;
  --color-secondary-200: #ffec9b;
  --color-secondary-300: #ffe263;
  --color-secondary-400: #ffd82b;
  --color-secondary-500: #f2c500;
  --color-secondary-600: #c4a000;
  --color-secondary-700: #967a00;
  --color-secondary-800: #685500;
  --color-secondary-900: #3a3000;

  --color-danger-50: #f9dddd;
  --color-danger-100: #f5cccc;
  --color-danger-200: #efaaaa;
  --color-danger-300: #e88787;
  --color-danger-400: #e26565;
  --color-danger-500: #db4343;
  --color-danger-600: #c12525;
  --color-danger-700: #921c1c;
  --color-danger-800: #631313;
  --color-danger-900: #340a0a;

  --color-theme-brand-50: #000;
  --color-theme-brand-100: #000;
  --color-theme-brand-200: #000;
  --color-theme-brand-300: #000;
  --color-theme-brand-400: #000;
  --color-theme-brand-500: #000;
  --color-theme-brand-600: #000;
  --color-theme-brand-700: #000;
  --color-theme-brand-800: #000;
  --color-theme-brand-900: #000;

  --color-theme-accent-50: #000;
  --color-theme-accent-100: #000;
  --color-theme-accent-200: #000;
  --color-theme-accent-300: #000;
  --color-theme-accent-400: #000;
  --color-theme-accent-500: #000;
  --color-theme-accent-600: #000;
  --color-theme-accent-700: #000;
  --color-theme-accent-800: #000;
  --color-theme-accent-900: #000;
}
